
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/consulting/[filename]",
      function () {
        return require("private-next-pages/consulting/[filename].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/consulting/[filename]"])
      });
    }
  